import { useEffect, useState } from "react";
import { enums } from "../../lib/utils/constants";
import { WorpelDataProvider } from "../../lib/store/DataProviders";
import useWorpelStore from "../../lib/store/WorpelStore";
import useGameStore from "../../lib/store/GameStore";
import HowToPlayButton from "../../components/modals/HowToPlayButton";
import SearchInput from "../../components/SearchInput";
import GuessTable from "../../components/guess/GuessTable";
import ButtonSwitcher from "../../components/modals/ButtonSwitcher";
import { GuessRow } from "./GuessRow";
import StatsButton from "../../components/modals/StatsButton";
import { Hero } from "./Hero";
import { NetworkError } from "../../components/NetworkError";
import { HowToPlayBody } from "./HowToPlayBody";
import LegendsButton from "./components/modals/LegendsButton";
import { PlayLegendsFooter } from "../../components/modals/footers/PlayLegendsFooter";
import { Footer } from "../../components/Footer";
import { PlayFinalsFooter } from "../../components/modals/footers/PlayFinalsFooter";

function Worpel() {
  const [
    target,
    imgUrl,
    initError,
    fetchPlayers,
    setupLevel,
    levelNumber,
    levelResult,
    setLevelResult,
    setIsAnswerOpen,
    setIsResultOpen,
  ] = useGameStore((state) => [
    state.target,
    state.imgUrl,
    state.initError,
    state.fetchWorpelPlayers,
    state.setupWorpelLevel,
    state.levelNumber,
    state.levelResult,
    state.setLevelResult,
    state.setIsAnswerOpen,
    state.setIsResultOpen,
  ]);
  const history = useWorpelStore((state) => state.history);
  const [adsLoaded, setAdsLoaded] = useState(false);

  const init = async () => {
    await fetchPlayers?.();
    setupLevel();
  };

  // Set up game
  useEffect(async () => {
    await init();

    if (!adsLoaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      setAdsLoaded(true);
    }
  }, []);

  // Set level result
  useEffect(async () => {
    if (history.hasOwnProperty(levelNumber)) {
      if (history[levelNumber] !== "fail") {
        setLevelResult(enums.levelResult.WON);
      } else {
        setLevelResult(enums.levelResult.LOST);
      }
    } else {
      setLevelResult(enums.levelResult.TBD);
    }
  }, [history, levelNumber]);

  // Preload silhouette
  useEffect(() => {
    if (imgUrl) {
      // console.log("loading", imgUrl);
      const img = new Image();
      img.src = imgUrl;
      window[imgUrl] = img;
    }
  }, [imgUrl]);

  // Launch modal if level has result
  useEffect(() => {
    // console.log({ target, levelResult });
    if (target) {
      if (levelResult === enums.levelResult.WON) {
        setIsAnswerOpen(true);
      } else if (levelResult === enums.levelResult.LOST) {
        setIsResultOpen(true);
      }
    }
  }, [target, levelResult]);

  return (
    <WorpelDataProvider>
      <div className="relative min-h-[calc(var(--vh,1vh)*100)]">
        <header className=" bg-worpel-blue">
          <div className="mx-auto flex max-w-xl justify-between px-1 py-1 md:justify-between">
            <HowToPlayButton
              modalTitle="Guess the mystery player!"
              modalBody={<HowToPlayBody />}
            />
            <LegendsButton />
            <StatsButton />
          </div>
        </header>
        <main className="container mx-auto px-4 pb-20 text-center md:pb-32">
          <Hero />
          <SearchInput />
          {!initError && <ButtonSwitcher footer={<PlayFinalsFooter />} />}
          <GuessTable
            guessRowType={GuessRow}
            columnNames={["", "Team", "State", "Pos", "Deb", "Ht", "Age", "#"]}
          />
          {initError && <NetworkError onRetry={init} />}
        </main>
        <Footer />
      </div>
    </WorpelDataProvider>
  );
}

export default Worpel;
